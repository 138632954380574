<div class="container py-gutter">
  <div>
    <div class="h-64 relative overflow-hidden">
      <img class="w-full h-full object-cover" src="assets/img/demo/landscape.jpg">
      <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>
    </div>

    <div class="z-10 relative -mt-16 px-gutter flex items-center">
      <label class="hoverable" for="fileInput">
        <img @scaleIn
           class="avatar h-24 w-24 flex-none align-start hidden sm:block border-3 border-white"
            [src]="userData.PicturePath" onError="this.src='assets/default/download.png'"> 
        <p class="hover-text">Upload Image</p>
        <div class="background"></div>
      </label>
      <input class="fileInput" id="fileInput" type='file' accept=".png" (change)="uploadFile($event)">

      <div class="w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
        <div class="h-16 flex items-center">
          <h1 @fadeInRight class="headline text-contrast-white m-0">{{userData.FirstName}} {{userData.LastName}}</h1>
        </div>
        <div >
          <mat-tab-group class="grid profile-tabs clean-tabs w-full" (selectedTabChange)="changeTab($event)">
            <mat-tab label="Profile">
            </mat-tab>
            <!-- <mat-tab label="Recent Activity">
            </mat-tab> -->
          </mat-tab-group>
        </div>
      </div>
    </div>

    <div class="mt-6 flex flex-col md:flex-row md:items-start" *ngIf="aboutTab">
      <div class="flex-auto">
        <form #f="ngForm" [formGroup]="form">
          <div class="card">
            <div class="border-b" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="44px">
              <div class="px-6 py-4">
                <h2 class="title m-0">About</h2>
              </div>
              <div class="mr-4" fxLayout="row" fxLayoutGap="10px">
                <button mat-mini-fab *ngIf="showInput == false; else saveButton" (click)="updateProfile(true)" color="primary" type="button">
                  <mat-icon [icIcon]="icEdit"></mat-icon>
                </button>
                <ng-template #saveButton>
                  <button mat-mini-fab [disabled]="disabledSaveButton" (click)="saveUserProfile()" color="primary" type="button">
                    <mat-icon [icIcon]="icSave" *ngIf="!disabledSaveButton" matPrefix></mat-icon>
                    <mat-spinner *ngIf="disabledSaveButton" color="accent" diameter="20">
                    </mat-spinner>
                  </button>
                  <button color="primary" (click)="close()" mat-mini-fab  type="button">
                    <mat-icon [icIcon]="icClose"></mat-icon>
                  </button>
                </ng-template>
              </div>
            </div>
            <!-- <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="loader;else content">
              <mat-progress-spinner strokeWidth="4" diameter="60" mode="indeterminate" color="primary">
              </mat-progress-spinner>
            </div>
            <ng-template #content> -->
              <div class="px-6 py-3" gdColumns="1fr 1fr" gdColumns.xs="1fr" gdGap="20px">
                <div class="py-3" *ngIf="showInput == false; else input" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icPI" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.FirstName}} {{userData.LastName}}</p>
                    <p class="m-0 caption text-hint">Name</p>
                  </div>
                </div>
                <ng-template #input>
                  <mat-form-field class="mt-6 flex-auto">
                    <mat-label>First Name</mat-label>
                    <input type="text" formControlName="FirstName" matInput>
                    <mat-icon [icIcon]="icPI" class="mr-3" matPrefix></mat-icon>
                  </mat-form-field>
                  <mat-form-field class="mt-6 flex-auto">
                    <mat-label>Last Name</mat-label>
                    <input type="text" formControlName="LastName" matInput>
                    <mat-icon [icIcon]="icPI" class="mr-3" matPrefix></mat-icon>
                  </mat-form-field>
                </ng-template>
                <div class="py-3" *ngIf="showInput == false; else input1" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icPI" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.UserName}}</p>
                    <p class="m-0 caption text-hint">Username</p>
                  </div>
                </div>
                <ng-template #input1>
                  <mat-form-field class="flex-auto">
                    <mat-label>User Name <span class="validation-red-color">*</span></mat-label>
                    <input type="text" formControlName="UserName" matInput placeholder="User Name">
                    <mat-icon [icIcon]="icPI" class="mr-3" matPrefix></mat-icon>
                    <mat-error class="validation-red-color" *ngIf="inputState(form, f, 'UserName')">
                      User Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>

                <div class="py-3" *ngIf="showInput == false; else input2" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icMail" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.Email}}</p>
                    <p class="m-0 caption text-hint">Email</p>
                  </div>
                </div>
                <ng-template #input2>
                  <mat-form-field class="flex-auto">
                    <mat-label>Email <span class="validation-red-color">*</span></mat-label>
                    <input type="email" formControlName="Email" matInput placeholder="Email">
                    <mat-icon [icIcon]="icMail" class="mr-3" matPrefix></mat-icon>
                    <mat-error class="validation-red-color" *ngIf="inputState(form, f, 'Email')">
                      Email is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </ng-template>
                <div class="py-3" *ngIf="showInput == false; else input3" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icPhone" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.PhoneNumber}}</p>
                    <p class="m-0 caption text-hint">Phone Number</p>
                  </div>
                </div>
                <ng-template #input3>
                  <mat-form-field class="flex-auto">
                    <mat-label>Phone #</mat-label>
                    <input type="text" formControlName="PhoneNumber" matInput placeholder="Phone #">
                    <mat-icon [icIcon]="icPhone" class="mr-3" matPrefix></mat-icon>
                  </mat-form-field>
                </ng-template>
                <div class="py-3" *ngIf="showInput == false; else input4" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icMyLocation" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.Address}}</p>
                    <p class="m-0 caption text-hint">Address</p>
                  </div>
                </div>
                <ng-template #input4>
                  <mat-form-field class="flex-auto">
                    <mat-label>Address</mat-label>
                    <input type="text" formControlName="Address" matInput>
                    <mat-icon [icIcon]="icMyLocation" class="mr-3" matPrefix></mat-icon>
                  </mat-form-field>
                </ng-template>
                <div class="py-3" *ngIf="showInput == false; else input5" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icMyLocation" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.City}}</p>
                    <p class="m-0 caption text-hint">City</p>
                  </div>
                </div>
                <ng-template #input5>
                  <mat-form-field class="flex-auto">
                    <mat-label>City</mat-label>
                    <input type="text" formControlName="City" matInput>
                    <mat-icon [icIcon]="icMyLocation" class="mr-3" matPrefix></mat-icon>
                  </mat-form-field>
                </ng-template>
                <div class="py-3" *ngIf="showInput == false; else input6" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icMyLocation" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.State}}</p>
                    <p class="m-0 caption text-hint">State</p>
                  </div>
                </div>
                <ng-template #input6>
                  <mat-form-field class="flex-auto">
                    <mat-label>State</mat-label>
                    <input type="text" formControlName="State" matInput>
                    <mat-icon [icIcon]="icMyLocation" class="mr-3" matPrefix></mat-icon>
                  </mat-form-field>
                </ng-template>
                <div class="py-3" *ngIf="showInput == false; else input7" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                      class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icMyLocation" size="20px"></ic-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{userData.Zip}}</p>
                    <p class="m-0 caption text-hint">Zip</p>
                  </div>
                </div>
                <ng-template #input7>
                  <mat-form-field class="flex-auto">
                    <mat-label>Zip</mat-label>
                    <input type="text" formControlName="Zip" matInput>
                    <mat-icon [icIcon]="icMyLocation" class="mr-3" matPrefix></mat-icon>
                  </mat-form-field>
                </ng-template>
              </div>
            <!-- </ng-template> -->
          </div>
        </form>
      </div>

      <div class="card max-w-unset md:max-w-xs w-full h-full md:ltr:ml-6 md:rtl:mr-6 mt-6 md:mt-0 my">
        <div class="px-6 py-4 border-b">
          <h2 class="title m-0">Actions</h2>
        </div>

        <div @stagger class="px-6 py-3" fxLayout="column" fxLayoutGap="24px">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div>
              <div *ngIf="emailmessage == false">
                <div class="py-3 action" fxLayout="row" *ngIf="confirmEmail == false; else disableEmail" fxLayoutAlign="start center" (click)="sendEmail()">
                  <div @scaleIn
                    class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                    <ic-icon [icon]="icMail" size="20px"></ic-icon>
                  </div>
                  <div @fadeInRight>
                    <p class="m-0 body-1">Resend Confirmation Email</p>
                  </div>
                </div>
              </div>
              <ng-template #disableEmail>
                <div class="py-3" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                    class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                      <mat-icon [icIcon]="icCheck"></mat-icon>
                  </div>
                  <div @fadeInRight>
                    <p class="m-0 body-1">Email Confirmed!</p>
                  </div>
                </div>
              </ng-template>
              <div class="py-3" fxLayout="row" *ngIf="emailmessage == true" fxLayoutAlign="start center">
                <div @scaleIn
                  class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                  <ic-icon [icon]="icMail" size="20px"></ic-icon>
                </div>
                <div @fadeInRight>
                  <p class="m-0 body-1">Email Sent</p>
                </div>
              </div>
              <div class="py-4 action" fxLayout="row" *ngIf="confirmedPhone == false; else disableClick" fxLayoutAlign="start center" (click)="enable2FA()">
                <div @scaleIn
                  class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                  <!-- <ic-icon [icon]="icPhoneMsg" size="20px"></ic-icon>         -->
                  <mat-icon>phonelink_lock</mat-icon>
                </div>
                <div @fadeInRight>
                  <p class="m-0 body-1">Enable 2FA</p>
                </div>
              </div>
              <ng-template #disableClick>
                <div class="py-4" fxLayout="row" fxLayoutAlign="start center">
                  <div @scaleIn
                    class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                      <mat-icon [icIcon]="icCheck"></mat-icon>
                  </div>
                  <div @fadeInRight>
                    <p class="m-0 body-1">Confirmed Enable 2FA</p>
                  </div>
                </div>
              </ng-template>
              <div class="py-4 action" fxLayout="row" fxLayoutAlign="start center" (click)="changePassword()">
                <div @scaleIn
                  class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                  <ic-icon [icon]="icLock" size="20px"></ic-icon>
                </div>

                <div @fadeInRight>
                  <p class="m-0 body-1">Change Password</p>
                </div>
              </div>
              <div class="py-4 action" fxLayout="row" fxLayoutAlign="start center" (click)="changeSessionTime()">
                <div @scaleIn
                  class="w-10 h-10 rounded-full bg-primary-light text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                  <ic-icon [icon]="icWatch" size="20px"></ic-icon>
                </div>

                <div @fadeInRight>
                  <p class="m-0 body-1">Session Time</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="recentActivityTab"></div>
  </div>
</div>