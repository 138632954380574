import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icMail from '@iconify/icons-ic/twotone-mail';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icPhoneMsg from '@iconify/icons-ic/perm-phone-msg';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '@vex/components/popover/popover/popover-ref';
import { AuthService } from '@vex/http-service/auth.service';
import { Icons } from '@vex/icons/icons';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '@app/user-setting/change-password/change-password.component';
import { EnableFAComponent } from '@app/user-setting/enable-fa/enable-fa.component';
import { stagger40ms } from '@vex/animations/stagger.animation';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from '@vex/animations/scale-fade-in.animation';
import { scaleIn400ms } from '@vex/animations/scale-in.animation';
import { fadeInRight400ms } from '@vex/animations/fade-in-right.animation';
import { UserService } from '@vex/http-service/user.service';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})

export class ToolbarUserDropdownComponent extends Icons implements OnInit {

  items: MenuItem[] = [
    {
      id: '1',
      icon: icAccountCircle,
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route: '/user-setting/user-profile'
    },
    // {
    //   id: '2',
    //   icon: icTableChart,
    //   label: 'Billing Information',
    //   description: 'Pricing & Current Plan',
    //   colorClass: 'text-purple',
    //   route: '/pages/pricing'
    // },
  ];


  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;
  icPhoneMsg = icPhoneMsg;
  
  isLoggedOut: boolean = false;
  name: string = '';
  userData: any = {};
  phoneNumber: any;
  profilePicture: any = '';
  confirmEmail: boolean = false;
  confirmNumber: boolean = false;
  email: any = '';
  emailmessage: boolean = false;
  menuClose: boolean = false;

  constructor(private cd: ChangeDetectorRef,
    private authService: AuthService,
    private dialog: MatDialog,
    private userService: UserService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) { 
      super();
    this.name = this.authService.getUserProperty("Name");
    this.profilePicture = this.authService.getUserProperty("PicturePath");
    this.confirmEmail = this.authService.getUserProperty("EmailConfirmed");
    this.confirmNumber = this.authService.getUserProperty("PhoneNumberConfirmed");
    this.email = this.authService.getUserProperty("Email");
  }

  ngOnInit() {
    console.log(this.confirmEmail);
  }

  close() {
    this.popoverRef.close();
  }

  logout() {
    this.authService.isShowExpiryMessage = false;
    this.isLoggedOut = true;
    this.authService.logout()
  }

  getUserInfo() {
    this.userService.getUserInfo().subscribe(res => {
      console.log(res);
      this.userData = res;
      this.phoneNumber = this.userData.PhoneNumber;
    })
  }

  changePassword(){
    
    this.dialog.open(ChangePasswordComponent, {
      width: '40%',
      disableClose: true,
    }).afterClosed().subscribe((r) => {
      if (r) {
      }
    });
  }
}
