import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputControl } from '@vex/utils/input-control';
import { Icons } from '@vex/icons/icons';
import { HelperService } from '@vex/services/helper.service';
import { UserService } from '@vex/http-service/user.service';
import { ConfirmWithCodeViewModel } from '@vex/model/user';
import { Toast, ToastrService } from 'ngx-toastr';
import {FieldMandatory, Createsuccess, Updatesuccess} from '@vex/constants/settings';

@Component({
  selector: 'enable-fa',
  templateUrl: './enable-fa.component.html',
  styleUrls: ['./enable-fa.component.scss']
})

export class EnableFAComponent extends Icons implements OnInit {

  fa2Form: FormGroup;
  mode: 'Create' | 'Update' = 'Create';
  control: FormArray;
  error: any;
  disabledButton: boolean = false;
  disabledSendButton: boolean = false;
  isShowTime: boolean = false;
  phoneSpinner: boolean = false;
  smsDisable: boolean = false;
  showTimer: boolean = false;
  timeLeft: number = 60;
  interval;
  heading: string = 'Enable 2FA';

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialogRef: MatDialogRef<EnableFAComponent>,
    private formBuilder: FormBuilder,
    private inputControl: InputControl,
    public helperService: HelperService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private userService: UserService,
  ) {
    super();
  }

  
  ngOnInit() {
    if(this.defaults.Heading) {
      this.heading = this.defaults.Heading;
    }
    this.fa2Form = this.formBuilder.group({
      Phone: [{value: this.defaults.PhoneNumber, disabled: true}, Validators.required],
      SMS: [{value: '', disabled: true}, Validators.required],
    }, { updateOn: 'blur' });
  }

  inputState(formControl, form, attr) {
    return this.inputControl.isErrorState(formControl.controls[attr], form);
  }

  onPhone(value){
    if(!this.fa2Form.get('Phone').hasError('required')){
      this.phoneSpinner = true;
      this.userService.checkPhone(value).subscribe(response => {
        this.phoneSpinner = false;
        if(response)
          this.fa2Form.get('Phone').setErrors({duplicate: true});
      });
    } 
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {   
        this.timeLeft--;
      } else {
        this.disabledSendButton = false;
        this.isShowTime = false;
        this.fa2Form.get('SMS').disable();
        clearInterval(this.interval);
      }
      return;
    },1000)
  }

  sendCode(){
    this.timeLeft = 60;
    this.disabledSendButton = true;
    this.isShowTime = false;
    this.fa2Form.get('SMS').enable();
    let value = this.fa2Form.getRawValue();
    let phone = value.Phone;
    let data: ConfirmWithCodeViewModel = <ConfirmWithCodeViewModel>{ PhoneNumber: phone }
    this.userService.sendCode(data).subscribe(response => {
      this.toastrService.info('SMS Code has been sent')
      this.isShowTime = true;
      this.startTimer();
    }, error => {
      this.disabledSendButton = false;
      this.error = error.error;
      console.log(this.error)
    });
  }

  confirm(){
    if (!this.fa2Form.valid) {
      return;
    }
    const user = this.fa2Form.getRawValue();
    let data: ConfirmWithCodeViewModel = <ConfirmWithCodeViewModel>{ PhoneNumber: user.Phone, PhoneCode: user.SMS }
    this.disabledButton = true;
    this.userService.verifyCode(data).subscribe(response => {
      this.disabledButton = false;
      if(response?.Status == 'approved') {
        this.dialogRef.close(true);
      } else {
        this.toastrService.warning("The Code has expired or is incorrect. Please try again.");
      }
    }, error => {
      this.disabledButton = false;
      this.error = error.error;
      console.log(this.error)
    });
  }
}
