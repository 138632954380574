// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false, 
    googleMapsApiKey: '',
    API_URL: 'https://api.kumorai.io/api/',
    WORKER_URL: 'https://worker.kumorai.io/',
    CLIENT_URL: 'https://portal.kumorai.io/',
    CLIENT_ID: '0c352225-c039-4dde-9f5c-e0b6982c6a4a',
    TENANT_ID: '4b4df018-c0dc-40f0-8139-d9d120a79b81',
    FILE_NAME: 'local-environment',
    CLIENT_NAME: 'Development',
    BUILD_VERSION: 'V 1.8'
};
