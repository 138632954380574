import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@vex/http-service/auth.service';
import { LayoutService } from '@vex/services/layout.service';
import { ConfigService } from '@vex/services/config.service';
import { ThemeID } from '@vex/constants/settings';
import { BreadcrumbsService } from '@vex/constants/breadcrumbs';
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  themeId: string = ThemeID;
  stateUrl: string = '';
  constructor(
    private authService: AuthService,
    private layoutService: LayoutService,
    public configService: ConfigService,
    private breadcrumbsService: BreadcrumbsService,
    private titleService: Title,
    private router: Router) {
    this.configService.config$.subscribe((t) => {
      this.themeId = t.id;
      this.displayHeader(this.stateUrl)
    })
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    this.stateUrl = url;
    this.displayHeader(url);
    this.displaySettingIcon(url);
    let data = route.data;
    let access = true;
    let title: string = 'Kumorai - Multi-Cloud Automation, Orchestration, Compliance & Governance';
    if(data.id != undefined) {
      access = this.authService.getRights(data.type, data.id);
      title = this.getTitle(data.id);
    }
    if(!access)
      this.router.navigate(['/unauthorized']);
    this.titleService.setTitle(title);
    return (this.checkLogin(url) && access);
  }

  getTitle(id) {
    let title = 'Kumorai - Multi-Cloud Automation, Orchestration, Compliance & Governance';
    let breadcrumbs = this.breadcrumbsService.getBreadcrumbsByFeatureId(id);
    if(breadcrumbs) {
      title = breadcrumbs.join(' . ');
    }
    return title;
  }
  // || url.toLowerCase().includes('/deployment/deploy-blueprint/')
  displayHeader(url) {
    this.layoutService.showHeader = true;
    if ((url.toLowerCase().includes('/network-management/blueprintdesigner/create')
      || url.toLowerCase().includes('/network-management/blueprintdesigner/edit')
      || url.toLowerCase().includes('/network-management/delete-meta-management')
      || url.toLowerCase().includes('/platform-settings/create-template-wizard')
      || url.toLowerCase().includes('/application-settings/create-cloud')
      || url.toLowerCase().includes('/application-settings/create-integrations')
      || url.toLowerCase().includes('/application-settings/update-integrations')
      || url.toLowerCase().includes('/network-management/create-workflow-template')
      || url.toLowerCase().includes('/network-management/claim-vpc')
      || url.toLowerCase().includes('/network-management/bulk-vpcs')
      || url.toLowerCase().includes('/network-management/new-cloud-migration')
      || url.toLowerCase().includes('/network-management/update-cloud-migration')
      || url.toLowerCase().includes('deployment/change-request-inventory')
      || url.toLowerCase().includes('/application-settings/delete-cloud')
      || url.toLowerCase().includes('/security/create-application')
      || url.toLowerCase().includes('/security/change-process')
      || url.toLowerCase().includes('/compliance/update-benchmark')
      || url.toLowerCase().includes('/compliance/create-benchmark')
      || url.toLowerCase().includes('/compliance/update-policy')
      || url.toLowerCase().includes('/compliance/check')
      || url.toLowerCase().includes('/network-management/connectivity-discovery')
      || url.toLowerCase().includes('compliance/bulk-assessment')
      || url.toLowerCase().includes('/network-management/meta-management')
      || url.toLowerCase().includes('/compliance/create-policy')
      || url.toLowerCase().includes('/network-management/create-location'))
      && this.themeId == ThemeID)
      this.layoutService.showHeader = false;
  }

  displaySettingIcon(url) {
    this.layoutService.showSettingIcon = false;
    if (url.toLowerCase().includes('/dashboard'))
      this.layoutService.showSettingIcon = true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  checkLogin(url: string) {
    this.authService.redirectUrl = url;
    return this.authService.isLoggedIn();
  }
}
